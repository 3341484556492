import { useWallet } from '@hooks/useWallet';
import Button from 'components/primitives/Button';
import { PropsWithChildren } from 'react';

export const ConnectWalletButton = ({ children }: PropsWithChildren) => {
  const { connect } = useWallet();

  return (
    <Button color='primary' size='normal' onClick={connect}>
      {children}
    </Button>
  );
};
