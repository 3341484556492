import {
  createContext,
  memo,
  PropsWithChildren,
  useContext,
  useMemo,
  useRef,
} from 'react';
import { Box, Button, Flex } from '../primitives';
import { useHotkeys } from 'react-hotkeys-hook';
import Link from 'next/link';
import { ConnectWalletButton } from 'components/ConnectWalletButton';
import { useMediaQuery } from 'react-responsive';
import { useMarketplaceChain } from '../../hooks';
import { useAccount } from 'wagmi';
import { AccountSidebar } from 'components/navbar/AccountSidebar';
import React from 'react';
import { useWallet } from '@hooks/useWallet';
import { useScroll } from '@hooks/useScroll';
import { Select } from '@v2/components/Select/Select';
import { truncateAddress } from '@utils/truncate';
import { styled } from '@stitches.config';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid';

interface Props {
  collectionSelectorVariant?: 'ghost' | 'fancy';
  noBackgroundOnScroll?: boolean;
  showExploreButton?: boolean;
  topLeftSlot?: React.ReactNode;
  topRightSlot?: React.ReactNode;
  sidebarColor?: 'fancy' | 'ghost';
  topPadding?: boolean;
}

const ColorContext = createContext<{ color: 'fancy' | 'ghost' }>({
  color: 'fancy',
});

const accountTriggerStyles = {
  pointerEvents: 'none',
};

const AccountTrigger = ({
  children,
  active,
}: PropsWithChildren<{ active?: boolean }>) => {
  const { color } = useContext(ColorContext);
  return (
    <StyledAccountTrigger color={color}>
      {children}
      <div style={accountTriggerStyles as any}>
        {active ? (
          <ChevronUpIcon width={30} height={30} aria-hidden='true' />
        ) : (
          <ChevronDownIcon width={30} height={30} aria-hidden='true' />
        )}
      </div>
    </StyledAccountTrigger>
  );
};

const __Navbar = ({
  children,
  noBackgroundOnScroll = false,
  topLeftSlot,
  topRightSlot,
  sidebarColor = 'ghost',
  topPadding = true,
}: PropsWithChildren<Props>) => {
  const { disconnect } = useWallet();
  const { isConnected, address } = useAccount();
  const isMobile = useMediaQuery({ maxWidth: 905 });
  const { routePrefix } = useMarketplaceChain();
  let searchRef = useRef<HTMLInputElement>(null);
  const scrollY = useScroll();

  useHotkeys('meta+k', (e) => {
    e.preventDefault();
    if (searchRef?.current) {
      searchRef?.current?.focus();
    }
  });

  const memoizedOptions = useMemo(() => {
    return [
      <Link key='portfolio' href={`/portfolio/${address}?chain=${routePrefix}`}>
        <Button color='transparent' size='normal'>
          Portfolio
        </Button>
      </Link>,
      <AccountSidebar key='wallet' />,
      <Button
        key='disconnect'
        color='errorNoBorder'
        size='normal'
        onClick={disconnect as any}
      >
        Disconnect
      </Button>,
    ];
  }, [address, routePrefix, disconnect]);

  return (
    <ColorContext.Provider value={{ color: sidebarColor }}>
      <StyledNavbar
        direction='column'
        justify='between'
        noBackgroundOnScroll={noBackgroundOnScroll}
        scrolled={scrollY > 0}
        topPadding={topPadding && !isMobile}
      >
        <NavbarContent justify='between'>
          {topLeftSlot ? topLeftSlot : <div />}
          <NavbarInnerContent>
            {topRightSlot}
            {isConnected ? (
              <Select
                Trigger={AccountTrigger}
                Dropdown={
                  sidebarColor === 'fancy'
                    ? AccountDropdownFancy
                    : AccountDropdownGhost
                }
                value={truncateAddress(address!)}
                items={memoizedOptions}
              ></Select>
            ) : (
              <ConnectWalletButton>Connect</ConnectWalletButton>
            )}
          </NavbarInnerContent>
        </NavbarContent>
        {children}
      </StyledNavbar>
    </ColorContext.Provider>
  );
};

export const Navbar = memo(__Navbar);

const StyledAccountTrigger = styled(Button, {
  fontSize: '15px !important',
  fontWeight: 500,
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  gap: '$1',
  paddingLeft: 16,
  paddingRight: 6,
  variants: {
    color: {
      ghost: {
        backgroundColor: '$neutralBg !important',
        color: '$textColor !important',
      },
      fancy: {
        backgroundColor: 'none',
        color: '$textColor !important',
      },
    },
  },
});

const AccountDropdownFancy = styled(Box, {
  background: '$fancyBg',
  padding: '$1',
  boxShadow: '0px 3.85px 19.27px 0px #00000014',
});

const AccountDropdownGhost = styled(Box, {
  background: '$neutralBg',
  padding: '$1',
  boxShadow: '0px 3.85px 19.27px 0px #00000014',
});

const StyledNavbar = styled(Flex, {
  width: '100%',
  margin: '0 auto',
  zIndex: 50,
  position: 'sticky',
  top: 0,
  borderBottom: 'none',
  paddingTop: '$2',
  paddingBottom: '$2',
  paddingLeft: '$2',
  paddingRight: '$2',

  variants: {
    noBackgroundOnScroll: {
      true: {
        backgroundColor: 'unset',
      },
      false: {
        backgroundColor: '$neutralBg',
      },
    },
    scrolled: {
      true: {
        borderBottom: '1px solid $borderColor',
      },
    },
    topPadding: {
      true: {
        paddingTop: '$4',
      },
    },
  },

  '@md': {
    paddingLeft: 32,
    paddingRight: 32,
  },
});

const NavbarContent = styled(Flex, {
  gap: '$2',
});

const NavbarInnerContent = styled(Flex, {
  gap: '$3',
});
