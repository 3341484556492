import { FC, useEffect } from 'react';
import { AnimatedOverlay, Content } from 'components/primitives/Dialog';
import { useAccount } from 'wagmi';
import { useENSResolver } from 'hooks';
import { Button, Flex, Text } from 'components/primitives';
import * as DialogPrimitive from '@radix-ui/react-dialog';
import { AnimatePresence, motion } from 'framer-motion';
import CopyText from 'components/common/CopyText';
import Link from 'next/link';
import Wallet from './Wallet';
import { useRouter } from 'next/router';
import { useMediaQuery } from 'react-responsive';
import { IconClose } from '@v2/icons/IconClose';
import { useOpenState } from '@hooks/useOpenState';
import { useEscKey } from '@hooks/useEscKey';
import { useWallet } from '@hooks/useWallet';

export const AccountSidebar: FC = () => {
  const { address } = useAccount();
  const { disconnect } = useWallet();
  const { asPath } = useRouter();
  const isSmallDevice = useMediaQuery({ maxWidth: 905 });
  let { shortAddress, shortName: shortEnsName } = useENSResolver(address);
  const { open, isOpen, close, setIsOpen } = useOpenState({});

  useEffect(close, [asPath]);
  useEscKey(close);

  return (
    <DialogPrimitive.Root onOpenChange={setIsOpen} open={isOpen}>
      <DialogPrimitive.DialogTrigger asChild>
        <Button onClick={open} color='transparent' size='normal'>
          Wallet
        </Button>
      </DialogPrimitive.DialogTrigger>
      <AnimatePresence>
        {isOpen && (
          <DialogPrimitive.DialogPortal forceMount>
            <AnimatedOverlay
              css={{ backgroundColor: '$sidebarOverlay' }}
              style={{ opacity: 0.6 }}
            />
            <Content
              onOpenAutoFocus={(e) => e.preventDefault()}
              forceMount
              asChild
              css={{
                right: 0,
                top: 0,
                bottom: 0,
                transform: 'none',
                left: 'unset',
                width: 495,
                maxWidth: '100%',
                minWidth: 'initial',
                height: '100dvh',
                maxHeight: '100dvh',
                background: '$gray1',
                border: 0,
                borderRadius: '$radiusNone',
              }}
            >
              <motion.div
                transition={{ type: 'tween', duration: 0.4 }}
                initial={{
                  opacity: 0,
                  right: '-100%',
                }}
                animate={{
                  opacity: 1,
                  right: 0,
                }}
                exit={{
                  opacity: 0,
                  right: '-100%',
                }}
              >
                <Flex
                  direction='column'
                  css={{
                    height: '100%',
                    gap: '$4',
                    p: '$4',
                    '@md': { gap: '$5', p: '$5' },
                  }}
                >
                  <Button
                    color='transparent'
                    css={{ color: '$gray10', ml: 'auto' }}
                    onClick={close}
                  >
                    <IconClose />
                  </Button>
                  <Flex direction='column' css={{ gap: '$3' }}>
                    {shortEnsName && (
                      <Text style='subtitle1'>{shortEnsName}</Text>
                    )}
                    <Flex justify='between' align='center' css={{ gap: '$4' }}>
                      <CopyText
                        text={address || ''}
                        css={{ width: 'max-content' }}
                      >
                        <Flex direction='column' css={{ gap: '$4' }}>
                          <Flex
                            align='center'
                            css={{
                              gap: 8,
                              color: '$gray11',
                              cursor: 'pointer',
                            }}
                          >
                            <Flex
                              justify='center'
                              align='center'
                              css={{
                                border: '1px solid $borderColor',
                                display: 'inline-flex',
                                padding: '$2 $3',
                                borderRadius: '$radiusMedium',
                              }}
                            >
                              <Text style='body1' css={{ color: '$gray11' }}>
                                {shortAddress}
                              </Text>
                            </Flex>
                            <Text style='body3' color='subtle'>
                              Copy
                            </Text>
                          </Flex>
                        </Flex>
                      </CopyText>
                      {!isSmallDevice && (
                        <Button color='disconnect' onClick={disconnect as any}>
                          Disconnect Wallet
                        </Button>
                      )}
                    </Flex>
                  </Flex>
                  <Flex direction='column' css={{ gap: '$4' }}>
                    <Link
                      href={`/portfolio/${address || ''}?tab=items`}
                      replace={true}
                    >
                      <span>My Items</span>
                    </Link>
                    <Link
                      href={`/portfolio/${address || ''}?tab=listings`}
                      replace={true}
                    >
                      <span>Listings</span>
                    </Link>
                    <Link
                      href={`/portfolio/${address || ''}?tab=offers`}
                      replace={true}
                    >
                      <span>Offers Made</span>
                    </Link>
                    <Link
                      href={`/portfolio/${address || ''}?tab=activity`}
                      replace={true}
                    >
                      <span>Activity</span>
                    </Link>
                  </Flex>

                  <Wallet />

                  {isSmallDevice && (
                    <div>
                      <Button color='disconnect' onClick={disconnect as any}>
                        Disconnect Wallet
                      </Button>
                    </div>
                  )}
                </Flex>
              </motion.div>
            </Content>
          </DialogPrimitive.DialogPortal>
        )}
      </AnimatePresence>
    </DialogPrimitive.Root>
  );
};
