import { useEffect, useState } from 'react';

const scrollableContainerSelector = '#scrollable_container';

export const useScroll = () => {
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    const scrollableContainerEl = document.querySelector(
      scrollableContainerSelector,
    );
    const onScroll = () => {
      setScrollY(scrollableContainerEl?.scrollTop ?? 0);
    };
    scrollableContainerEl?.addEventListener('scroll', onScroll);
    onScroll();
    return () => {
      scrollableContainerEl?.removeEventListener('scroll', onScroll);
    };
  }, []);

  return scrollY;
};
