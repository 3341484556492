import {
  Box,
  Button,
  Flex,
  FormatCryptoCurrency,
  FormatCurrency,
  Text,
} from 'components/primitives';
import { mainnet, polygon, optimism } from 'wagmi/chains';
import { useAccount, useReadContracts, useBalance } from 'wagmi';
import { Address, erc20Abi } from 'viem';
import { useMemo, useState } from 'react';
import { zeroAddress, formatUnits } from 'viem';
import { useCoinConversion } from '@hooks';
import { Currency } from '@api/orderbook_api/v1/types.pb';
import { useSignals } from '@preact/signals-react/runtime';
import { sCurrencies, sSymbols } from '@signals/currency';
import { int } from '@api/utils';
import { useWatchQueryKey } from '@hooks/useWatchQueryKey';

type EnhancedCurrency = Currency & {
  usdPrice: number;
  balance: string | number | bigint;
};

const Wallet = () => {
  useSignals();
  const [viewAll, setViewAll] = useState(false);
  const { address } = useAccount();

  const currencies = sCurrencies.value;
  const nonNativeCurrencies = currencies.filter(
    (currency) => currency.address !== zeroAddress,
  );
  const currencySymbols = sSymbols.value.join(',');
  const currencyCoingeckoIds = currencies
    .map((currency) => currency.coinGeckoId)
    .join(',');

  const { data: nonNativeBalances, queryKey } = useReadContracts({
    contracts: nonNativeCurrencies.map((currency) => ({
      abi: erc20Abi,
      address: currency.address as Address,
      chainId: int(currency.chainId),
      functionName: 'balanceOf',
      args: [address as Address],
    })),
    query: {
      enabled: address ? true : false,
    },
    allowFailure: false,
  });

  useWatchQueryKey({ queryKey });

  //CONFIGURABLE: Configure these by just changing the chainId to fetch native balance info, in addition to changing this
  // also make sure you change the enhancedCurrencies function to take into account for these new balances
  const ethBalance = useBalance({
    address,
    chainId: mainnet.id,
  });

  const maticBalance = useBalance({
    address,
    chainId: polygon.id,
  });

  const usdConversions = useCoinConversion(
    'USD',
    currencySymbols,
    currencyCoingeckoIds,
  );

  const enhancedCurrencies = useMemo(() => {
    const currencyToUsdConversions = usdConversions.reduce((map, data) => {
      map[data.symbol] = data;
      map[(data as any).coinGeckoId] = data;
      return map;
    }, {} as Record<string, (typeof usdConversions)[0]>);

    return currencies.map((currency, i) => {
      let balance: string | number | bigint = 0n;
      if (currency.address === zeroAddress) {
        //CONFIGURABLE: Configure these to show the fetched balance results configured above in the useBalance hooks
        switch (int(currency.chainId)) {
          case polygon.id: {
            balance = maticBalance.data?.value || 0n;
            break;
          }
          case mainnet.id: {
            balance = ethBalance.data?.value || 0n;
            break;
          }
        }
      } else {
        const index = nonNativeCurrencies.findIndex(
          (nonNativeCurrency) =>
            nonNativeCurrency.chainId === currency.chainId &&
            nonNativeCurrency.symbol === currency.symbol &&
            nonNativeCurrency.coinGeckoId === currency.coinGeckoId,
        );
        balance =
          nonNativeBalances &&
          nonNativeBalances[index] &&
          (typeof nonNativeBalances[index] === 'string' ||
            typeof nonNativeBalances[index] === 'number' ||
            typeof nonNativeBalances[index] === 'bigint')
            ? (nonNativeBalances[index] as string | number | bigint)
            : 0n;
      }

      const conversion =
        currencyToUsdConversions[
          currency.coinGeckoId
            ? currency.coinGeckoId
            : currency.symbol!.toLowerCase()
        ];

      const usdPrice =
        Number(formatUnits(BigInt(balance), currency?.decimals || 18)) *
        (conversion?.price || 0);
      return {
        ...currency,
        usdPrice,
        balance,
      };
    }) as EnhancedCurrency[];
    //CONFIGURABLE: Configure these to regenerate whenever a native balance changes, non native balances are already handled
  }, [usdConversions, nonNativeBalances, ethBalance, maticBalance]);

  const totalUsdBalance = useMemo(() => {
    return enhancedCurrencies.reduce(
      (total, { usdPrice }) => total + usdPrice,
      0,
    );
  }, [enhancedCurrencies]);

  const visibleCurrencies = viewAll
    ? enhancedCurrencies
    : enhancedCurrencies.slice(0, 3);

  return (
    <Flex
      direction='column'
      css={{
        border: '1px solid $gray5',
        borderRadius: '$radiusSmall',
        mt: '$3',
      }}
    >
      <Flex direction='column' css={{ gap: '$5', p: 22 }}>
        <Flex direction='column' css={{ gap: '$1' }}>
          <Text style='subtitle2' color='subtle'>
            Total Balance
          </Text>
          <FormatCurrency
            style='h4'
            amount={totalUsdBalance}
            css={{ mb: '$4', fontWeight: 500, fontSize: 28 }}
          />
        </Flex>
        <Flex direction='column' css={{ gap: '$4' }}>
          {visibleCurrencies.map((currency, i) => {
            return (
              <Flex key={i} css={{ width: '100%', gap: '$3' }} align='center'>
                <Flex
                  direction='column'
                  justify='center'
                  css={{ width: '100%' }}
                >
                  <Flex justify='between'>
                    <Text>{currency.symbol}</Text>
                    <FormatCryptoCurrency
                      css={{ fontWeight: 500 }}
                      amount={currency.balance}
                      symbol={currency.symbol}
                      decimals={currency.decimals}
                      textStyle='body1'
                      small={false}
                    />
                  </Flex>
                  <Flex justify='between'>
                    <Text style='body2' color='subtle'>
                      {currency.name}
                    </Text>
                    <Text style='body2' color='subtle'></Text>
                    <FormatCurrency color='subtle' amount={currency.usdPrice} />
                  </Flex>
                </Flex>
              </Flex>
            );
          })}
        </Flex>
        {visibleCurrencies.length < enhancedCurrencies.length && (
          <Box>
            <Button
              onClick={() => {
                setViewAll(!viewAll);
              }}
              color='ghost'
              css={{ color: '$btnSubtleColor' }}
            >
              Show {viewAll ? 'fewer' : 'all'} tokens
            </Button>
          </Box>
        )}
      </Flex>
    </Flex>
  );
};

export default Wallet;
