import { styled } from '@stitches.config';
import { Box } from 'components/primitives';
import { PropsWithChildren } from 'react';

const Container = styled(Box, {
  background: '$neutralBg',
});

export const Layout = ({ children }: PropsWithChildren) => {
  return (
    <Container>
      <main>{children}</main>
    </Container>
  );
};
