import { Button } from 'components/primitives';
import { useCart } from '@reservoir-kit-ui-overrides/hooks';
import { CartPopover } from '@reservoir-kit-ui-overrides';

const CartButton = () => {
  const { data: cartItems } = useCart((cart) => cart.items);

  return (
    <CartPopover
      trigger={
        <Button color='fancy'>
          Cart
          {cartItems.length > 0 && ' ' + cartItems.length}
        </Button>
      }
    />
  );
};

export default CartButton;
