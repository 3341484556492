import { FC } from 'react';
import NextHead from 'next/head';
import { useSignals } from '@preact/signals-react/runtime';
import { sMarketplace } from '@signals/marketplace';
import { MarketplaceConfig } from '@api/orderbook_api/v1/types.pb';

type Props = {
  title?: string;
  description?: string;
  image?: string;
  pathname?: `/${string}`;
};

export const Head: FC<Props> = ({
  title,
  description,
  image,
  pathname = '',
}) => {
  useSignals();

  const marketplace = sMarketplace.value;

  if (!marketplace || !marketplace.meta) {
    return null;
  }

  const { favicon } = marketplace;
  const { domain } = marketplace.meta as MarketplaceConfig;
  title = title || marketplace.meta.title;
  description = description || marketplace.meta.description;
  image = image || marketplace.meta.image;
  const url = `https://${domain}${pathname}`;

  return (
    <NextHead>
      <title>{title}</title>
      <meta name='description' content={description} />
      <link rel='icon' type='image/x-icon' href={favicon} />
      <meta property='og:url' content={url} />
      <meta property='og:type' content='website' />
      <meta property='og:title' content={title} />
      <meta property='og:description' content={description} />
      <meta property='og:image' content={image} />
      <meta name='twitter:card' content='summary_large_image' />
      <meta property='twitter:domain' content={domain} />
      <meta property='twitter:url' content={url} />
      <meta name='twitter:title' content={title} />
      <meta name='twitter:description' content={description} />
      <meta name='twitter:image' content={image} />
    </NextHead>
  );
};
