import React, { PropsWithChildren } from 'react';
import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { Box, Button, Flex } from '@components/primitives';
import { styled } from '@stitches.config';

export type TriggerVariant = 'ghost' | 'fancy';

interface SelectProps {
  items: React.ReactNode[];
  value: React.ReactNode;
  triggerVariant?: TriggerVariant;
  className?: string;
  Trigger?: React.FunctionComponent<
    PropsWithChildren<{ variant?: TriggerVariant; active?: boolean }>
  >;
  Dropdown?: React.FunctionComponent<PropsWithChildren<{}>>;
  size?: 'large' | 'normal';
}

const DefaultTrigger = styled(Button, {
  px: 16,
  fontSize: '15px !important',
  fontWeight: 500,
  w: '100%',
  display: 'flex',
  justifyContent: 'space-between',
});

const DefaultDropdown = styled(Box, {
  background: '$dropdownBg',
  borderRadius: '$radiusMedium',
  p: '$1',
});

const DefaultItem = styled(Box, {
  cursor: 'pointer',
  borderRadius: '$radiusMedium',
  variants: {
    active: {
      true: {
        backgroundColor: '$dropdownActiveBg',
      },
    },
  },
});

export const Select: React.FC<SelectProps> = ({
  items,
  value,
  Trigger,
  Dropdown,
  size = 'normal',
  triggerVariant = 'ghost',
}) => {
  Dropdown = Dropdown ?? DefaultDropdown;

  return (
    <Menu as='div' style={{ position: 'relative' }}>
      <MenuButton style={{ width: '100%' }} as='div'>
        {({ active }) => {
          if (Trigger) {
            return (
              <Trigger variant={triggerVariant} active={active}>
                {value}
              </Trigger>
            );
          }
          return (
            <DefaultTrigger
              size={size}
              color={triggerVariant}
              css={{ fontWeight: 500, color: '$btnSubtleText' }}
            >
              {value}
              <ChevronDownIcon width={18} height={18} aria-hidden='true' />
            </DefaultTrigger>
          );
        }}
      </MenuButton>

      <MenuItems
        unmount={false}
        style={{
          position: 'absolute',
          zIndex: 101,
          minWidth: '100%',
          boxShadow: '0px 12px 52px 0px #00000026',
          marginTop: 4,
          borderRadius: '$radiusMedium',
          overflow: 'hidden',
        }}
      >
        <Dropdown>
          <Flex direction='column'>
            {items.map((item, index) => (
              <MenuItem key={index}>
                {({ active }) => (
                  <DefaultItem active={active}>{item}</DefaultItem>
                )}
              </MenuItem>
            ))}
          </Flex>
        </Dropdown>
      </MenuItems>
    </Menu>
  );
};
