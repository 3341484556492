import { computed, effect, signal } from '@preact/signals-react';
import { sCollections } from './collection';
import supportedChains from '@api/utils/chains';
import { Currency } from '@api/orderbook_api/v1/types.pb';

const __currenciesMap = signal<Map<string, Currency> | undefined>(undefined);

effect(() => {
  const map = new Map<string, Currency>();
  sCollections.value?.forEach((collection) => {
    const chain = supportedChains.find(
      (chain) => chain.id === +collection.chainId!,
    );
    (chain?.listingCurrencies ?? []).forEach((currency) => {
      map.set(currency.symbol!, {
        ...currency,
        chainId: chain?.id.toString(),
        name: chain?.name,
      });
    });
  });
  __currenciesMap.value = map;
});

export const sSymbols = computed(() => {
  return Array.from(__currenciesMap.value?.keys() || []);
});

export const sCurrencies = computed(() => {
  return Array.from(__currenciesMap.value?.values() ?? []);
});

export const sCurrenciesMap = computed(() => {
  return __currenciesMap.value;
});
